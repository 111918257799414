module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"includeInDevelopment":true,"id":"GTM-NK2TW8L","defaultDataLayer":{"type":"object","value":{"event":"pageMetaData","page":{"type":"home","title":"Arduino Wiki","environment":"prd","region":"global","brand":"arduino","language":"EN"}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"exclude":["/dev-404-page/","/404/","/404.html"],"trailingSlashes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":800,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-copy-linked-files","options":{"ignoreFileExtensions":["png","jpg","jpeg"]}},"gatsby-remark-containers",{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false,"offsetY":"75","elements":["h2","h3","h4"]}},{"resolve":"gatsby-remark-prismjs"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Arduino","short_name":"Arduino","start_url":"/","background_color":"#ffffff","theme_color":"#008184","display":"minimal-ui","icon":"src/assets/favicon.ico","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"23330209a9e4870226e34963941206b6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"Mjvf0GrvmQYNDH0GfIhND5e8aGFynScR","trackPage":false,"host":"https://evs.aayinltcs.arduino.cc","delayLoad":false,"delayLoadTime":1000,"manualLoad":false,"customSnippet":"!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error(\"Segment snippet included twice.\");else{analytics.invoked=!0;analytics.methods=[\"trackSubmit\",\"trackClick\",\"trackLink\",\"trackForm\",\"pageview\",\"identify\",\"reset\",\"group\",\"track\",\"ready\",\"alias\",\"debug\",\"page\",\"once\",\"off\",\"on\",\"addSourceMiddleware\",\"addIntegrationMiddleware\",\"setAnonymousId\",\"addDestinationMiddleware\"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement(\"script\");t.type=\"text/javascript\";t.async=!0;t.src=\"https://evs.aayinltcs.arduino.cc/5yy37B6M2UBwaTzKNi37Ls/qUh2Pce5EsyeGTFGda1mDz.min.js\";var n=document.getElementsByTagName(\"script\")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey=\"Mjvf0GrvmQYNDH0GfIhND5e8aGFynScR\";analytics._cdn = \"https://evs.aayinltcs.arduino.cc\";analytics.SNIPPET_VERSION=\"4.15.3\";analytics.load(\"Mjvf0GrvmQYNDH0GfIhND5e8aGFynScR\");}}();"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
