// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-become-distributor-index-js": () => import("./../src/pages/en/become-distributor/index.js" /* webpackChunkName: "component---src-pages-en-become-distributor-index-js" */),
  "component---src-pages-en-contact-us-index-js": () => import("./../src/pages/en/contact-us/index.js" /* webpackChunkName: "component---src-pages-en-contact-us-index-js" */),
  "component---src-pages-en-distribution-contact-us-index-js": () => import("./../src/pages/en/distribution/contact-us/index.js" /* webpackChunkName: "component---src-pages-en-distribution-contact-us-index-js" */),
  "component---src-pages-en-donate-index-js": () => import("./../src/pages/en/donate/index.js" /* webpackChunkName: "component---src-pages-en-donate-index-js" */),
  "component---src-pages-en-newsletter-cloud-confirmation-js": () => import("./../src/pages/en/newsletter/cloud-confirmation.js" /* webpackChunkName: "component---src-pages-en-newsletter-cloud-confirmation-js" */),
  "component---src-pages-en-newsletter-confirmation-js": () => import("./../src/pages/en/newsletter/confirmation.js" /* webpackChunkName: "component---src-pages-en-newsletter-confirmation-js" */),
  "component---src-pages-en-newsletter-error-js": () => import("./../src/pages/en/newsletter/error.js" /* webpackChunkName: "component---src-pages-en-newsletter-error-js" */),
  "component---src-pages-en-newsletter-learn-more-js": () => import("./../src/pages/en/newsletter/learn-more.js" /* webpackChunkName: "component---src-pages-en-newsletter-learn-more-js" */),
  "component---src-pages-en-newsletter-subscription-js": () => import("./../src/pages/en/newsletter/subscription.js" /* webpackChunkName: "component---src-pages-en-newsletter-subscription-js" */),
  "component---src-pages-en-newsletter-survey-js": () => import("./../src/pages/en/newsletter/survey.js" /* webpackChunkName: "component---src-pages-en-newsletter-survey-js" */),
  "component---src-pages-en-newsletter-thank-you-js": () => import("./../src/pages/en/newsletter/thank-you.js" /* webpackChunkName: "component---src-pages-en-newsletter-thank-you-js" */),
  "component---src-pages-en-newsletter-unsubscribed-js": () => import("./../src/pages/en/newsletter/unsubscribed.js" /* webpackChunkName: "component---src-pages-en-newsletter-unsubscribed-js" */),
  "component---src-pages-en-software-download-thank-you-js": () => import("./../src/pages/en/software/download-thank-you.js" /* webpackChunkName: "component---src-pages-en-software-download-thank-you-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-common-common-page-template-js": () => import("./../src/templates/Common/commonPageTemplate.js" /* webpackChunkName: "component---src-templates-common-common-page-template-js" */),
  "component---src-templates-guide-guide-homepage-template-js": () => import("./../src/templates/Guide/guideHomepageTemplate.js" /* webpackChunkName: "component---src-templates-guide-guide-homepage-template-js" */),
  "component---src-templates-main-software-template-js": () => import("./../src/templates/Main/softwareTemplate.js" /* webpackChunkName: "component---src-templates-main-software-template-js" */),
  "component---src-templates-tutorial-tutorial-homepage-template-js": () => import("./../src/templates/Tutorial/tutorialHomepageTemplate.js" /* webpackChunkName: "component---src-templates-tutorial-tutorial-homepage-template-js" */),
  "component---src-templates-tutorial-tutorial-section-template-js": () => import("./../src/templates/Tutorial/tutorialSectionTemplate.js" /* webpackChunkName: "component---src-templates-tutorial-tutorial-section-template-js" */)
}

